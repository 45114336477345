<template>
  <e-sidebar
    :title="edit ? $t('Editar Conta corrente') : $t('Nova Conta corrente')"
    :show="showSidebar"
    :fetching="loading"
    :saving="saving"
    width="500px"
    @save="saveAccount"
    @hidden="showSidebar = false"
  >
    <template #content>
      <FormulateForm
        ref="formCheckingAccount"
        name="formCheckingAccount"
        @submit="saveAccount"
      >
        <b-row>
          <b-col md="12">
            <e-store-combo v-model="formAccount.storeId" />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="checking-account-active"
              v-model="formAccount.active"
              type="switch"
              :label="$t('Ativo?')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="checking-account-is-internal"
              v-model="formAccount.isInternal"
              type="switch"
              :label="$t('Interna?')"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="checking-account-name"
              v-model="formAccount.name"
              type="text"
              class="required"
              :label="$t('Nome')"
              validation="required"
            />
          </b-col>
        </b-row>
        <b-row v-if="isInternal">
          <b-col md="3">
            <FormulateInput
              id="checking-account-is-exchange"
              v-model="formAccount.isExchange"
              type="switch"
              :label="$t('Troco?')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="checking-account-is-safe-box"
              v-model="formAccount.isSafeBox"
              type="switch"
              :label="$t('Cofre?')"
            />
          </b-col>
        </b-row>
        <b-row v-if="!isInternal">
          <b-col md="2">
            <FormulateInput
              id="checking-account-is-main"
              v-model="formAccount.isMain"
              type="switch"
              :label="$t('Principal?')"
            />
          </b-col>
          <b-col md="10">
            <FormulateInput
              id="checking-account-bank"
              v-model="formAccount.bankNumber"
              type="vue-select"
              :label="$t('Banco')"
              :placeholder="$t('Selecione')"
              :options="banks()"
              @input="cleanBankData"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="checking-account-branch"
              v-model="formAccount.branchNumber"
              type="number"
              :label="$t('Agência')"
              :disabled="bankNotSelected"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="checking-account-branch-digit"
              v-model="formAccount.branchCheckDigit"
              type="number"
              :label="$t('Digito')"
              :disabled="bankNotSelected"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="checking-account-account"
              v-model="formAccount.accountNumber"
              type="number"
              :label="$t('Conta')"
              :disabled="bankNotSelected"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="checking-account-account-digit"
              v-model="formAccount.accountCheckDigit"
              type="number"
              :label="$t('Digito')"
              :disabled="bankNotSelected"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { banks } from '@/mixins'
import { ESidebar, EStoreCombo } from '@/views/components'

const getInitialForm = () => ({
  id: null,
  name: '',
  isInternal: false,
  bankNumber: null,
  branchNumber: null,
  branchCheckDigit: null,
  accountNumber: null,
  accountCheckDigit: null,
  isPrincipal: false,
  isSafeBox: false,
  isExchange: false,
  active: true,
})

export default {
  components: { BRow, BCol, ESidebar, EStoreCombo },

  mixins: [banks],

  data() {
    return {
      showSidebar: false,
      saving: false,
      loading: false,
      formAccount: getInitialForm(),
    }
  },

  computed: {
    isExchange() {
      return this.formAccount.isExchange
    },
    isSafeBox() {
      return this.formAccount.isSafeBox
    },
    edit() {
      return !!this.formAccount.id
    },

    isInternal() {
      return !!this.formAccount.isInternal
    },

    bankNotSelected() {
      return !this.formAccount.bankNumber
    },
  },

  watch: {
    isExchange(val) {
      if (val) {
        this.formAccount.isSafeBox = false
      }
    },
    isSafeBox(val) {
      if (val) {
        this.formAccount.isExchange = false
      }
    },
  },

  methods: {
    async onShowSidebar(idAccount = null) {
      try {
        this.formAccount = getInitialForm()
        this.showSidebar = true
        if (idAccount) {
          this.loading = true
          await this.fetchAccount(idAccount)
        }
      } catch (error) {
        this.showGenericError({})
      } finally {
        this.loading = false
      }
    },

    async fetchAccount(id) {
      this.formAccount = (await this.$http.get(`/api/financial/checking-accounts/by-id/${id}`)).data
      this.formAccount.storeId = this.formAccount.store?.id
      this.formAccount.bankNumber = this.formAccount.bankNumber?.toString().padStart(3, '0')
    },

    cleanBankData() {
      const { branchNumber, branchCheckDigit, accountNumber, accountCheckDigit } = getInitialForm()
      this.formAccount = {
        ...this.formAccount,
        branchNumber,
        branchCheckDigit,
        accountNumber,
        accountCheckDigit,
      }
    },

    async saveAccount() {
      try {
        this.saving = true
        if (this.formAccount.isInternal) {
          this.formAccount.bankNumber = null
          this.cleanBankData()
        }
        await this.$http({
          url: '/api/financial/checking-accounts',
          method: this.edit ? 'PUT' : 'POST',
          data: this.formAccount,
        })
        this.showSidebar = false
        this.showSuccess({})
        this.$emit('save', this.formAccount)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>

<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="filters-store"
              v-model="filters.storeId"
              :required="false"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="active"
              v-model="filters.active"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Selecione')"
              :options="activeInactiveTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="checking-account-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('CHECKING_ACCOUNT.NAME'), 'female')"
        :fields="fields"
        :items="checkingAccounts"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(isInternal)="row">
          <e-status-badge
            :status="row.item.isInternal"
            :type="'yesNo'"
          />
        </template>

        <template #cell(isMain)="row">
          <e-status-badge
            :status="row.item.isMain"
            :type="'yesNo'"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-update="$can('Update', 'CheckingAccount')"
            :show-delete="false"
            @update="updateAccount(row)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="checking-account-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <checking-account-sidebar
      ref="checkingAccountSidebar"
      @save="getData"
    />

    <fab
      :main-tooltip="$t('Adicionar conta corrente')"
      @click="createAccount"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EStoreCombo } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { banks, statusTypes } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import CheckingAccountSidebar from './CheckingAccountSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    CheckingAccountSidebar,
    EStoreCombo,
    EStatusBadge,
    EFilters,
    EGridActions,
  },

  mixins: [banks, statusTypes],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/settings/financial/checkingAccount', {
      checkingAccounts: 'checkingAccounts',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Interna'),
          key: 'isInternal',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Banco'),
          key: 'bankNumber',
          formatter: value => this.getBankName(value) || '-',
          sortable: true,
        },
        {
          label: this.$t('Agência'),
          key: 'branchNumber',
          formatter: (value, row, item) =>
            item.branchNumber
              ? `${item.branchNumber}${item.branchCheckDigit ? `-${item.branchCheckDigit}` : ''}`
              : '-',
          sortable: true,
        },
        {
          label: this.$t('Conta'),
          key: 'accountNumber',
          formatter: (value, row, item) =>
            item.accountNumber
              ? `${item.accountNumber}${item.accountCheckDigit ? `-${item.accountCheckDigit}` : ''}`
              : '-',
        },
        {
          label: this.$t('Principal'),
          key: 'isMain',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/settings/financial/checkingAccount', [
      'fetchCheckingAccounts',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    getBankName(bankNumber) {
      return this.getBank(bankNumber)?.label
    },
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCheckingAccounts()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    updateAccount(row) {
      const { id } = row.item
      this.$refs.checkingAccountSidebar.onShowSidebar(id)
    },

    createAccount() {
      this.$refs.checkingAccountSidebar.onShowSidebar()
    },

    async inactiveAccount(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          console.log(row)
          // await this.$http.delete(`/api/product-groups/${group.id}`)
          this.getData()
          this.showSuccess({ message: this.$t('Conta corrente inativado com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
